
@font-face {
  font-family: "sandvik-sans-headline";
  font-weight: 800;
  src: url("./assets/fonts/SandvikSansHeadline-ExtraBold.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-headline";
  font-weight: 700;
  src: url("./assets/fonts/SandvikSansHeadline-Bold.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-headline";
  font-weight: 600;
  src: url("./assets/fonts/SandvikSansHeadline-Bold.ttf") format("truetype"),
}


@font-face {
  font-family: "sandvik-sans-headline";
  font-weight: 500;
  src: url("./assets/fonts/SandvikSansHeadline-Regular.ttf") format("truetype"),
}


@font-face {
  font-family: "sandvik-sans-headline";
  font-weight: 400;
  src: url("./assets/fonts/SandvikSansHeadline-Regular.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-headline";
  font-weight: 300;
  src: url("./assets/fonts/SandvikSansHeadline-Light.ttf") format("truetype"),
}


@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 800;
  src: url("./assets/fonts/SandvikSansText-Bold.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 700;
  src: url("./assets/fonts/SandvikSansText-Bold.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 600;
  src: url("./assets/fonts/SandvikSansText-Bold.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 500;
  src: url("./assets/fonts/SandvikSansText-Regular.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 500;
  src: url("./assets/fonts/SandvikSansText-Regular.ttf") format("truetype"),
}


@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 400;
  src: url("./assets/fonts/SandvikSansText-Regular.ttf") format("truetype"),
}

@font-face {
  font-family: "sandvik-sans-text";
  font-weight: 300;
  src: url("./assets/fonts/SandvikSansText-Light.ttf") format("truetype"),
}


*{
  min-height: 0;
  box-sizing: border-box;
}

html, body {
  position: absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
  overflow: hidden auto;
}

body {  
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-family: 'sandvik-sans-text', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --deep-teal: #003034;
  --sherpa-blue: #00484E;
  --blue-stone: #006068;
  --geyser: #CFE4E4;
  --rio-grande: #C4D400;

  --primary-dark: var(--deep-teal);
  --primary-medium: var(--sherpa-blue);
  --primary-light:var(--blue-stone);

  --highlight-pale: var(--geyser);
  --highlight-green: var(--rio-grande);

  //background-color: var(--primary-dark);
  background-color: rgb(36, 36, 36) !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.analysis-drawer {
  height: max-content !important;
}

.main-logo {
  width: 8em;

  &.auth-logo {
    width: 60%;
  }

  &.loading {
    width: 100%;
  }

  &.header-logo {
    width: 40px;
    height: 30px;
  }
}

.data-sync-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  img {
    margin-bottom: 1em;
  }
  span {
    width: 100%;
  }
}

.wear-results {
  font-weight: bold;
  display: flex;
  flex-direction: row;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;

  p {
    padding: 0 !important
  }
}

.deck-accordion {
  overflow: hidden;

  .MuiCollapse-entered  {
    height: calc(100% - 64px) !important;

    div {
      height: 100%;
    }
  }
}