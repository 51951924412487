.app-header{
    
  display: flex;
  flex-direction: row;
  justify-content: space-between;  
  align-items: center;      
  height: 100%;
  .logo{
    width: 135px;
  }
}