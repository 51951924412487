#root {
  height: 100vh;
}

.app {
  display: flex;   
  height: 100%;
  @media only screen and (min-width: 600px) {
    height: 100vh;
  }

  .main{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: hidden; 

    .content {      
      flex: 1; 
      overflow: scroll;   
    }

  }
  .header {
    flex: 0 0 64px;    
  }  
  
  .version{
    align-self: center;
    color: var(--highlight-pale);
    flex: 0 0 24px;  
  }


  .choices-button-container {
    margin-top: 4em;
    text-align: right;

    .choices-button {
        margin: 0 1em 0 1em;
    }
  }
}